import React, { useCallback } from "react";
import { FieldArray, Field } from "redux-form";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import CustomCheckboxField from "../../../components/CustomCheckboxField";
import useStyles from "./styles";

const ContentCheckBoxField = ({
  loading,
  field,
  emptyMessage,
  title,
  data,
  labelKey = "label",
}) => {
  const classes = useStyles();
  const renderCheckboxFields = useCallback(
    ({ fields }) => {
      return fields.map((field, index) => {
        const label = data && data[index] && data[index][labelKey];
        return (
          <Grid item xs={12} sm={6} key={index}>
            <Field
              name={`${field}.checked`}
              component={CustomCheckboxField}
              label={label}
            />
          </Grid>
        );
      });
    },
    [data]
  );
  return (
    <>
      <Box className={classes.formSubtitleContainer}>
        <Typography classes={{ root: classes.formSubtitle }}>
          {title}
        </Typography>
      </Box>
      <Divider />
      <div className={classes.containerPadding}>
        {loading ? (
          <Grid
            container
            spacing={3}
            justify="center"
            alignItems="center"
            style={{ height: 160 }}
          >
            <CircularProgress color="secondary" />
          </Grid>
        ) : (
          <Grid container spacing={1}>
            {data && data.length <= 0 ? (
              <Typography
                variant="subtitle2"
                style={{ color: "rgba(0, 0, 0, 0.54)", padding: 15 }}
              >
                {emptyMessage}
              </Typography>
            ) : (
              <FieldArray name={field} component={renderCheckboxFields} />
            )}
          </Grid>
        )}
      </div>
    </>
  );
};

export default ContentCheckBoxField;
