export const Types = {
  SET_GROUPS_LOADING: "SET_GROUPS_LOADING",
  ASYNC_FETCH_GROUPS: "ASYNC_FETCH_GROUPS",
  SUCCESS_FETCH_GROUPS: "SUCCESS_FETCH_GROUPS",
  FAIL_FETCH_GROUPS: "FAIL_FETCH_GROUPS",
};

const INITIAL_VALUES = {
  data: [],
  loading: false,
};

export default (state = INITIAL_VALUES, action) => {
  switch (action.type) {
    case Types.SET_GROUPS_LOADING:
      const dataState = state.data ? state.data : [];
      return {
        ...state,
        loading: action.payload.loading,
        data: action.payload.loading ? [] : [...dataState],
      };
    case Types.SUCCESS_FETCH_GROUPS:
      return { ...state, data: action.payload.groups };
    case Types.FAIL_FETCH_GROUPS:
      return { ...state, ...INITIAL_VALUES };
    default:
      return state;
  }
};

export const fetchGroups = () => ({
  type: Types.ASYNC_FETCH_GROUPS,
});
