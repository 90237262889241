import React, { useEffect, useCallback } from "react";
import { connect } from "react-redux";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";

import useStyles from "./styles";
import TablePaginationButtons from "./TablePaginationButtons";
import { resetTableState } from "../../store/ducks/table";

const CustomTable = ({
  resetTableState,
  tableLoading,
  tablePadding,
  tableHeaders,
  tableData,
  currentPage,
  perPage,
  totalRows,
  parentHandlePagination,
}) => {
  const classes = useStyles();

  const renderHeaders = useCallback(
    () =>
      tableHeaders.map((header, index) => (
        <TableCell
          key={index}
          classes={{
            head: classes.tableHeadCell,
            paddingCheckbox: classes.paddingCheckbox,
          }}
        >
          {header.label}
        </TableCell>
      )),
    [tableHeaders, classes.tableHeadCell, classes.paddingCheckbox]
  );

  const renderCellData = useCallback(
    (rowData) => {
      return Object.entries(rowData).map(([key, value], cellKey) => (
        <TableCell
          key={cellKey}
          classes={{ paddingCheckbox: classes.paddingCheckbox }}
        >
          {value}
        </TableCell>
      ));
    },
    [classes.paddingCheckbox]
  );

  const renderData = useCallback(
    () =>
      tableData.map(({ id, ...rowData }) => (
        <TableRow key={id}>{renderCellData(rowData)}</TableRow>
      )),
    [tableData, renderCellData]
  );

  useEffect(() => () => resetTableState(), [resetTableState]);

  return (
    <Table padding={!tableLoading ? tablePadding : null}>
      {tableLoading && (
        <TableFooter>
          <TableRow>
            <TableCell>
              <LinearProgress color="secondary" />
            </TableCell>
          </TableRow>
        </TableFooter>
      )}

      {!tableLoading && tableData.length <= 0 && (
        <TableFooter>
          <TableRow>
            <TableCell align="center">
              <Typography variant="subtitle2">
                Não há dados a serem exibidos!
              </Typography>
            </TableCell>
          </TableRow>
        </TableFooter>
      )}

      {!tableLoading && tableData.length > 0 && (
        <>
          <TableHead>
            <TableRow>{renderHeaders()}</TableRow>
          </TableHead>

          <TableBody>{renderData()}</TableBody>

          {parentHandlePagination && perPage > 0 && (
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 20]}
                  count={totalRows}
                  page={currentPage ? currentPage - 1 : currentPage}
                  rowsPerPage={perPage}
                  onChangeRowsPerPage={({ target: { value } }) =>
                    parentHandlePagination(currentPage, value)
                  }
                  onChangePage={(page) =>
                    parentHandlePagination(
                      page,
                      perPage,
                      parentHandlePagination
                    )
                  }
                  labelDisplayedRows={({ from, to, count }) =>
                    `${from} - ${to} de ${count}`
                  }
                  labelRowsPerPage="Itens por página"
                  ActionsComponent={TablePaginationButtons}
                />
              </TableRow>
            </TableFooter>
          )}
        </>
      )}
    </Table>
  );
};

const mapStateToProps = (state) => ({
  currentPage: state.table.currentPage,
  perPage: state.table.perPage,
  totalRows: state.table.totalRows,
});

const mapDispatchToProps = {
  resetTableState,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomTable);
