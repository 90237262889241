import api from "./api";

export const fetchRoles = async (params) => {
  try {
    const { data } = await api().get("/roles", { params });
    return data;
  } catch (err) {
    const { data } = err.response;

    return data;
  }
};

export const findRole = async (roleId) => {
  try {
    const { data } = await api().get(`/roles/${roleId}`);

    return data;
  } catch (err) {
    console.error(err);

    return err.response.data;
  }
};

export const createRole = async (roleData) => {
  try {
    const { data } = await api().post("/roles", roleData);

    return data;
  } catch (err) {
    console.error(err);

    return err.response.data;
  }
};

export const updateRole = async (roleId, roleData) => {
  try {
    const { data } = await api().patch(`/roles/${roleId}`, roleData);

    return data;
  } catch (err) {
    console.error(err);

    return err.response.data;
  }
};

export const deleteRole = async (roleId) => {
  try {
    const { data } = await api().delete(`/roles/${roleId}`);

    return data;
  } catch (err) {
    console.error(err);

    return err.response.data;
  }
};
