import camelCaseKeys from "camelcase-keys-recursive";

import api, { apiPassport, apiSSO } from "./api";
import config from "../config";

export const authenticate = async (formData) => {
  try {
    const { data } = await apiPassport().post("/oauth/token", {
      client_id: config("passport_client_id"),
      client_secret: config("passport_client_secret"),
      grant_type: "password",
      ...formData,
    });

    return camelCaseKeys({ data });
  } catch (err) {
    const { status: statusCode } = err.response;

    switch (statusCode) {
      case 401:
        return {
          error: {
            title: "Invalid Request",
            message: "Login ou senha inválidos!",
          },
        };
      default:
        return err.response.data;
    }
  }
};

export const self = async (accessToken) => {
  try {
    const { data } = await api().get("/self", {
      headers: {
        authorization: accessToken,
      },
    });

    return data;
  } catch (err) {
    const { status: statusCode } = err.response;

    switch (statusCode) {
      case 404:
        return {
          error: {
            title: "Invalid Request",
            message: "Login ou senha inválidos!",
          },
        };
      default:
        return err.response.data;
    }
  }
};

export const ssoSession = async (accessToken) => {
  try {
    const { data } = await apiSSO().get("/session", {
      params: { token: accessToken },
    });

    return camelCaseKeys({ data });
  } catch (err) {
    const { status: statusCode } = err.response;

    switch (statusCode) {
      case 404:
        return {
          error: {
            title: "Not Found",
            message: "O recurso que você está tentando acessar não existe!",
          },
        };
      default:
        return err.response.data;
    }
  }
};

export const ssoRefresh = (refreshToken, sso) =>
  apiSSO().request({
    url: "login/refresh",
    method: "POST",
    data: { refresh_token: refreshToken, sso },
  });

export const logout = async (acessToken) => {
  try {
    const { data } = await apiPassport().delete("/api/oauth/token", {
      headers: {
        authorization: `Bearer ${acessToken}`,
      },
    });

    return { data };
  } catch (err) {
    const { status: statusCode } = err.response;

    switch (statusCode) {
      case 401:
        return {
          error: {
            title: "Invalid Token",
            message: "O token fornecido é inválido!",
          },
        };
      case 404:
        return {
          error: {
            title: "Not Found",
            message: "O recurso que você está tentando acessar não existe!",
          },
        };
      default:
        return err.response.data;
    }
  }
};
