import api from "./api";

export const fetchGroups = async (params) => {
  try {
    const { data } = await api().get("/groups", { params });
    return data;
  } catch (err) {
    const { data } = err.response;

    return data;
  }
};
