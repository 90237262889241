import api from "./api";

export const fetchUnits = async (params) => {
  try {
    const { data } = await api().get("/units", { params });
    return data;
  } catch (err) {
    const { data } = err.response;

    return data;
  }
};
