import storage from "redux-persist/lib/storage";
import { createMigrate } from "redux-persist";

// Types
export const Types = {
  ASYNC_AUTH: "ASYNC_AUTH",
  SUCCESS_AUTH: "SUCCESS_AUTH",
  FAIL_AUTH: "FAIL_AUTH",
  ASYNC_SSO_AUTHENTICATE: "ASYNC_SSO_AUTHENTICATE",
  ASYNC_FETCH_PERMISSIONS: "ASYNC_FETCH_PERMISSIONS",
  SUCCESS_FETCH_PERMISSIONS: "SUCCESS_FETCH_PERMISSIONS",
  ASYNC_LOGOUT: "ASYNC_LOGOUT",
  UPDATE_SELECTED_CUSTOMER: "UPDATE_SELECTED_CUSTOMER",
  LOGOUT: "LOGOUT",
};

// Reducer
const INITIAL_VALUES = {
  loading: false,
  selectedCustomerId: null,
  sso: null,
  token: null,
  user: null,
  permissions: [],
  permissionsLoaded: false,
};

export default (state = INITIAL_VALUES, action) => {
  switch (action.type) {
    case Types.ASYNC_AUTH:
      return { ...state, loading: true };
    case Types.SUCCESS_AUTH:
      return { ...state, ...action.payload, loading: false };
    case Types.FAIL_AUTH:
      return { ...state, loading: false };
    case Types.SUCCESS_FETCH_PERMISSIONS:
      return {
        ...state,
        permissions: action.payload.permissions,
        permissionsLoaded: true,
      };
    case Types.UPDATE_SELECTED_CUSTOMER:
      return { ...state, selectedCustomerId: action.payload.customerId };
    case Types.LOGOUT:
      return { ...INITIAL_VALUES };
    default:
      return state;
  }
};

// Action Creators
export const authenticate = (dataForm) => ({
  type: Types.ASYNC_AUTH,
  payload: {
    dataForm,
  },
});

export const loginSSO = (sso, accessToken) => ({
  type: Types.ASYNC_SSO_AUTHENTICATE,
  payload: {
    sso,
    accessToken,
  },
});

export const updateSelectedCustomer = (customerId) => ({
  type: Types.UPDATE_SELECTED_CUSTOMER,
  payload: {
    customerId,
  },
});

export const fetchPermissions = () => ({
  type: Types.ASYNC_FETCH_PERMISSIONS,
});

export const logout = () => ({
  type: Types.ASYNC_LOGOUT,
});

// Persist Config
const migrations = {
  0: (state) => {
    return {
      ...state,
      currentRoute: undefined,
    };
  },
};

export const getRedirectIntegrationUrl = (sso) => {
  const { protocol, hostname, port } = window.location;

  let redirectUrl = `${protocol}//${hostname}`;
  if (port) {
    redirectUrl += `:${port}`;
  }
  redirectUrl += "/login/sso";

  return `${
    process.env.REACT_APP_SSO_URL
  }/login?sso=${sso}&redirect=${encodeURIComponent(redirectUrl)}`;
};

export const authPersistConfig = {
  key: "auth",
  version: 0,
  migrate: createMigrate(migrations, { debug: false }),
  storage,
  blacklist: ["loading", "customerId"],
};
