import { takeLatest, put, call, select } from "redux-saga/effects";

import { Types as UnitsTypes } from "../ducks/unit";
import { fetchUnits } from "../../services/unit";

function* asyncFetchUnits() {
  try {
    yield put({
      type: UnitsTypes.SET_UNITS_LOADING,
      payload: { loading: true },
    });
    const customerId = yield select((state) => state.auth.selectedCustomerId);

    const params = { customerId };

    const { data } = yield call(fetchUnits, params);

    yield put({
      type: UnitsTypes.SUCCESS_FETCH_UNITS,
      payload: { units: data },
    });
  } catch (error) {
    yield put({ type: UnitsTypes.FAIL_FETCH_UNITS });
  } finally {
    yield put({
      type: UnitsTypes.SET_UNITS_LOADING,
      payload: { loading: false },
    });
  }
}

export default function* unitSaga() {
  yield takeLatest(UnitsTypes.ASYNC_FETCH_UNITS, asyncFetchUnits);
}
