import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { withStyles } from "@material-ui/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import { history } from "../../../routes/index";
import { updatePreviousRoute } from "../../../store/ducks/route";
import { fetchRoles } from "../../../store/ducks/role";
import { fetchGroups } from "../../../store/ducks/group";
import { fetchUnits } from "../../../store/ducks/unit";
import { importUsers } from "../../../store/ducks/user";
import config from "../../../config";
import styles from "./styles";
import SelectCustomer from "../../../components/SelectCustomer";
import FileInput from "../../../components/FileInput";
import ContentCheckBoxField from "../ContentCheckBoxField";

class UserImport extends Component {
  componentDidMount = () => {
    this.props.fetchRoles();
    this.props.fetchUnits();
    this.props.fetchGroups();
  };

  componentDidUpdate = (prevProps) => {
    const { roles, groups, units, selectedCustomerId } = this.props;

    if (roles.length > 0 && prevProps.roles.length === 0) {
      roles.forEach(({ id, label }, index) => {
        this.props.array.insert("roles", index, {
          checked: false,
          role: { id, label },
        });
      });
    }
    if (groups && groups.length > 0 && prevProps.groups.length === 0) {
      groups.forEach(({ id, name }, index) => {
        this.props.array.insert("groups", index, {
          checked: false,
          group: { id, name },
        });
      });
    }
    if (units && units.length > 0 && prevProps.units.length === 0) {
      units.forEach(({ id, unitName }, index) => {
        this.props.array.insert("units", index, {
          checked: false,
          unit: { id, unitName },
        });
      });
    }

    this.props.change("customer_id", selectedCustomerId);

    if (selectedCustomerId !== prevProps.selectedCustomerId) {
      this.props.array.removeAll("roles");
      this.props.fetchRoles();
      this.props.array.removeAll("units");
      this.props.fetchUnits();
      this.props.array.removeAll("groups");
      this.props.fetchGroups();
    }
  };

  componentWillUnmount() {
    this.props.updatePreviousRoute(this.props.match.url);
  }

  render() {
    const {
      classes,
      match,
      handleSubmit,
      submitting,
      previousRoute,
      roles,
      groups,
      units,
      loadingRoles,
      loadingGroups,
      loadingUnits,
      importUsers,
    } = this.props;

    const { url } = match;

    const loadingAll = loadingRoles || loadingGroups || loadingUnits;
    return (
      <form onSubmit={handleSubmit(importUsers)}>
        <Box className={classes.userControlRoot}>
          <Grid container classes={{ root: classes.userContentContainer }}>
            <Grid item sm={12}>
              <SelectCustomer url={url} />
            </Grid>
            <Grid
              container
              item
              xs={12}
              md={6}
              classes={{
                root: classes.formLeftSide,
              }}
            >
              <Grid
                item
                style={{ paddingBottom: 20 }}
                classes={{
                  root: classes.containerPadding,
                }}
              >
                <Grid item sm={12}>
                  <a
                    style={{ textDecoration: "none" }}
                    href={
                      config("passport_url") +
                      "/examples/importacao-de-usuarios.xlsx"
                    }
                    download
                  >
                    <Card className={classes.cardInverse}>
                      <Typography variant="h6">
                        Modelo de Arquivo para Importação
                      </Typography>
                      <Typography>
                        Clique aqui para fazer o download do modelo da planilha
                        para importação dos usuários.
                      </Typography>
                    </Card>
                  </a>
                </Grid>

                <Grid item sm={12} style={{ marginTop: 20 }}>
                  <Field
                    component={FileInput}
                    label="Selecione um Arquivo"
                    name="import_file"
                    accept=".csv,.xls,.xlsx"
                    type="file"
                    style={{ display: "none" }}
                  />
                </Grid>
                {/* 
                <Grid item sm={12} style={{ marginTop: 20 }}>
                  <Field
                    name={'change_passport_first_access'}
                    component={CustomCheckboxField}
                    label={'Os usuários deverão alterar a senha após o primeiro acesso ?'}
                  />
                </Grid> */}
              </Grid>
            </Grid>

            <Grid item xs={12} md={6} classes={{ root: classes.formRightSide }}>
              <ContentCheckBoxField
                title="Selecione os cargos a serem atribuídos ao novo usuário"
                emptyMessage="Não existe nenhum cargo disponível para o cliente selecionado"
                field="roles"
                loading={loadingRoles}
                data={roles}
                labelKey="label"
              />
            </Grid>
            <Grid item xs={12} md={6} classes={{ root: classes.formLeftSide }}>
              <ContentCheckBoxField
                title="Selecione as unidades a serem atribuídos ao novo usuário"
                emptyMessage="Não existe nenhuma unidade disponível para o cliente selecionado"
                field="units"
                loading={loadingUnits}
                data={units}
                labelKey="unitName"
              />
            </Grid>
            <Grid item xs={12} md={6} classes={{ root: classes.formLeftSide }}>
              <ContentCheckBoxField
                title="Selecione os grupos a serem atribuídos ao novo usuário"
                emptyMessage="Não existe nenhum grupo disponível para o cliente selecionado"
                field="groups"
                loading={loadingGroups}
                data={groups}
                labelKey="groupName"
              />
            </Grid>

            <Grid
              item
              xs={12}
              container
              justify="center"
              style={{ borderTop: "1px solid #DDD" }}
            >
              <Grid item xs={6} classes={{ root: classes.buttonContainer }}>
                {!submitting && (
                  <Button
                    variant="outlined"
                    color="secondary"
                    style={{ marginRight: 20 }}
                    classes={{ root: classes.button }}
                    className={classes.roundedButton}
                    onClick={() => history.push(previousRoute)}
                  >
                    Cancelar
                  </Button>
                )}
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  disabled={submitting || loadingAll}
                  classes={{ root: classes.button }}
                  className={classes.roundedButton}
                >
                  {submitting ? <CircularProgress size={20} /> : "Importar"}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </form>
    );
  }
}

UserImport = withStyles(styles)(UserImport);

const mapStateToProps = (state) => ({
  previousRoute: state.route.previousRoute,
  selectedCustomerId: state.auth.selectedCustomerId,
  roles: state.roles.data,
  groups: state.groups.data,
  units: state.units.data,
  loadingRoles: state.roles.loading,
  loadingGroups: state.groups.loading,
  loadingUnits: state.units.loading,
});

const mapDispatchToProps = {
  updatePreviousRoute,
  fetchRoles,
  fetchGroups,
  fetchUnits,
  importUsers,
};

UserImport = connect(mapStateToProps, mapDispatchToProps)(UserImport);

export default reduxForm({
  form: "FORM_IMPORT_USER",
})(UserImport);
