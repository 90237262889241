import React from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";

import config from "../config";
import AuthenticatedRoute from "./AuthenticatedRoute";
import Login from "../pages/Login";
import LoginSSO from "../pages/Login/LoginSSO";
import Customer from "../pages/Customer";
import User from "../pages/User";
import UserControl from "../pages/User/UserControl";
import Role from "../pages/Role";
import RoleControl from "../pages/Role/RoleControl";
import UserImport from "../pages/User/UserImport";
import UserImportLog from "../pages/User/UserImport/UserImportLog";

import PasswordForgot from "../pages/Password/Forgot";
import PasswordReset from "../pages/Password/Reset";

export const history = createBrowserHistory();

export default function Routes() {
  return (
    <Router basename={config("route_basename")} history={history}>
      <Switch>
        <Route path="/" component={Login} exact />

        <Route path="/login/sso" component={LoginSSO} />

        <AuthenticatedRoute
          path="/customers"
          component={Customer}
          permission="can-list-customers"
          headerTitle="Administração de Clientes"
          breadcrumbsConfig={[{ label: "Clientes", path: "/customers" }]}
          exact
        />

        <AuthenticatedRoute
          path="/customers/:customerId/users"
          component={User}
          permission="can-list-users"
          headerTitle="Administração de Usuários"
          breadcrumbsConfig={[
            { label: "Clientes", path: "/customers" },
            { label: "Usuários", path: "/customers/customerId/users" },
          ]}
          exact
        />

        <AuthenticatedRoute
          path="/customers/:customerId/users/new"
          component={UserControl}
          permission="can-create-users"
          headerTitle="Cadastro de Usuário"
          breadcrumbsConfig={[
            { label: "Clientes", path: "/customers" },
            { label: "Usuários", path: "/customers/customerId/users" },
            { label: "Novo", path: "/customers/customerId/users/new" },
          ]}
          exact
        />

        <AuthenticatedRoute
          path="/customers/:customerId/users/import"
          component={UserImport}
          permission="can-import-users"
          headerTitle="Importação de Usuários"
          breadcrumbsConfig={[
            { label: "Clientes", path: "/customers" },
            { label: "Usuários", path: "/customers/customerId/users" },
            { label: "Importar", path: "/customers/customerId/users/import" },
          ]}
          exact
        />

        <AuthenticatedRoute
          path="/customers/:customerId/users/import/:logId"
          component={UserImportLog}
          permission="can-import-users"
          headerTitle="Resultado da Importação de Usuários"
          breadcrumbsConfig={[
            { label: "Clientes", path: "/customers" },
            { label: "Usuários", path: "/customers/customerId/users" },
            { label: "Importar", path: "/customers/customerId/users/import" },
            {
              label: "Resultado",
              path: "/customers/customerId/users/import/logId",
            },
          ]}
          exact
        />

        <AuthenticatedRoute
          path="/customers/:customerId/users/:userId/update"
          component={UserControl}
          permission="can-edit-users"
          headerTitle="Edição de Usuário"
          breadcrumbsConfig={[
            { label: "Clientes", path: "/customers" },
            { label: "Usuários", path: "/customers/customerId/users" },
            {
              label: "Editar",
              path: "/customers/customerId/users/userId/update",
            },
          ]}
          exact
        />

        <AuthenticatedRoute
          path="/customers/:customerId/roles"
          component={Role}
          permission="can-list-roles"
          headerTitle="Administração de Cargos"
          breadcrumbsConfig={[
            { label: "Clientes", path: "/customers" },
            { label: "Cargos", path: "/customers/customerId/roles" },
          ]}
          exact
        />

        <AuthenticatedRoute
          path="/customers/:customerId/roles/new"
          permission="can-create-roles"
          component={RoleControl}
          headerTitle="Cadastro de Cargo"
          breadcrumbsConfig={[
            { label: "Clientes", path: "/customers" },
            { label: "Cargos", path: "/customers/customerId/roles" },
            { label: "Novo", path: "/customers/customerId/roles/new" },
          ]}
        />

        <AuthenticatedRoute
          path="/customers/:customerId/roles/:roleId/update"
          permission="can-edit-roles"
          component={RoleControl}
          headerTitle="Edição de Cargo"
          breadcrumbsConfig={[
            { label: "Clientes", path: "/customers" },
            { label: "Cargos", path: "/customers/customerId/roles" },
            {
              label: "Editar",
              path: "/customers/customerId/roles/roleId/update",
            },
          ]}
        />

        <Route path="/password/forgot" exact component={PasswordForgot} />
        <Route
          path="/password/reset"
          exact
          render={() => <Redirect to="/password/forgot" />}
        />
        <Route path="/password/reset/:token" exact component={PasswordReset} />
      </Switch>
    </Router>
  );
}
