import { all } from "redux-saga/effects";

import authSaga from "./auth";
import customComponentsSaga from "./customComponents";
import customerSaga from "./customer";
import userSaga from "./user";
import roleSaga from "./role";
import unitSaga from "./unit";
import groupSaga from "./group";
import applicationSaga from "./application";
import passwordSaga from "./password";
import alertSaga from "./alert";

export default function* rootSaga() {
  yield all([
    authSaga(),
    customComponentsSaga(),
    unitSaga(),
    customerSaga(),
    userSaga(),
    roleSaga(),
    groupSaga(),
    applicationSaga(),
    passwordSaga(),
    alertSaga(),
  ]);
}
