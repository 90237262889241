import { takeLatest, put, call, select } from "redux-saga/effects";

import { Types as GroupsTypes } from "../ducks/group";
import { fetchGroups } from "../../services/group";

function* asyncFetchGroups() {
  try {
    yield put({
      type: GroupsTypes.SET_GROUPS_LOADING,
      payload: { loading: true },
    });
    const customerId = yield select((state) => state.auth.selectedCustomerId);

    const params = { customerId };

    const { data } = yield call(fetchGroups, params);

    yield put({
      type: GroupsTypes.SUCCESS_FETCH_GROUPS,
      payload: { groups: data },
    });
  } catch (error) {
    yield put({
      type: GroupsTypes.FAIL_FETCH_GROUPS,
    });
  } finally {
    yield put({
      type: GroupsTypes.SET_GROUPS_LOADING,
      payload: { loading: false },
    });
  }
}

export default function* groupSaga() {
  yield takeLatest(GroupsTypes.ASYNC_FETCH_GROUPS, asyncFetchGroups);
}
