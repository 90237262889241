import api from "./api";

export const fetchUsers = async (params) => {
  try {
    const { data } = await api().get("/users", { params });

    return data;
  } catch (err) {
    const { data } = err.response;

    return data;
  }
};

export const createUser = async (userData) => {
  try {
    const { data } = await api().post(`/users`, userData);

    return data;
  } catch (err) {
    console.error(err);

    return err.response.data;
  }
};

export const findUser = async (userId) => {
  try {
    const { data } = await api().get(`/users/${userId}`);

    return data;
  } catch (err) {
    console.error(err);

    return err.response.data;
  }
};

export const updateUser = async (userId, userData) => {
  try {
    const { data } = await api().patch(`/users/${userId}`, userData);

    return data;
  } catch (err) {
    console.error(err);

    return err.response.data;
  }
};

export const deleteUser = async (userId) => {
  try {
    const { data } = await api().delete(`/users/${userId}`);

    return data;
  } catch (err) {
    console.error(err);

    return err.response.data;
  }
};

export const importUser = (data) => {
  const formData = new FormData();

  Object.entries(data).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((item, index) => {
        formData.append(`${key}[${index}]`, item);
      });
    } else {
      formData.append(key, value);
    }
  });

  return api().post("/users/import", formData);
};

export const logImportUser = (id) => api().get(`/users/import/${id}`);
