export const Types = {
  SET_UNITS_LOADING: "SET_UNITS_LOADING",
  ASYNC_FETCH_UNITS: "ASYNC_FETCH_UNITS",
  SUCCESS_FETCH_UNITS: "SUCCESS_FETCH_UNITS",
  FAIL_FETCH_UNITS: "FAIL_FETCH_UNITS",
};

const INITIAL_VALUES = {
  data: [],
  loading: false,
};

export default (state = INITIAL_VALUES, action) => {
  switch (action.type) {
    case Types.SET_UNITS_LOADING:
      const dataState = state.data ? state.data : [];
      return {
        ...state,
        loading: action.payload.loading,
        data: action.payload.loading ? [] : [...dataState],
      };
    case Types.SUCCESS_FETCH_UNITS:
      return { ...state, data: action.payload.units };
    case Types.FAIL_FETCH_UNITS:
      return { ...state, ...INITIAL_VALUES };
    default:
      return state;
  }
};
export const fetchUnits = () => ({
  type: Types.ASYNC_FETCH_UNITS,
});
