import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  formSubtitleContainer: {
    padding: "15px 35px",
  },

  formSubtitle: {
    fontSize: ".7rem",
    opacity: 0.6,
  },

  fieldsContainer: {
    padding: "25px 40px",
  },
  containerPadding: {
    padding: 20,
    paddingBottom: 8,
  },
}));
